function transformQuestionToUpdateModel(question) {
  return {
    id: question.id,
    text: {
      en: question.text.en,
      bg: question.text.bg
    },
    boolAnswer: question.boolAnswer
  };
}

function transformToBaseQuestionModel(question) {
  return {
    text: {
      en: question.text.en,
      bg: question.text.bg
    },
    type: question.type
  };
}

function transformToQuestionTrueFalseModel(question) {
  return {
    ...transformToBaseQuestionModel(question),
    boolAnswer: question.boolAnswer
  };
}

function transformToQuestionAnswersModel(question) {
  return {
    ...transformToBaseQuestionModel(question),
    answers: question.answers
  };
}

export default {
  transformQuestionToUpdateModel,
  transformToQuestionTrueFalseModel,
  transformToQuestionAnswersModel
};
