<template>
  <v-form ref="manageQuestionForm">
    <v-container fluid>
      <v-row>
        <v-col md="12" class="pb-0">
          <h3 class="pb-1">{{ $t('common.text') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            solo
            v-model="question.text.en"
            :rules="[rules.required]"
            :label="$t('languages.en')"
            class="pl-3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            solo
            v-model="question.text.bg"
            :rules="[rules.required]"
            :label="$t('languages.bg')"
            class="pl-3"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" class="pb-0">
          <h3>{{ $t('common.type') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="8">
          <v-select
            solo
            :items="questionTypesList"
            :label="$t('common.type')"
            :rules="[rules.required]"
            v-model="question.type"
            class="pl-3"
          ></v-select>
        </v-col>
      </v-row>
      <div v-if="question.type === questionTypes.trueOrFalse">
        <v-row>
          <v-col md="12" class="pb-0">
            <h3>{{ $t('common.answer') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" class="pt-0">
            <v-radio-group v-model="question.boolAnswer" row>
              <v-radio
                :label="$t('common.true')"
                :value="true"
                color="primary"
              ></v-radio>
              <v-radio
                :label="$t('common.false')"
                :value="false"
                color="primary"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col md="12" class="pb-0">
            <h3>{{ $t('common.answers') }}</h3>
          </v-col>
        </v-row>
        <v-card class="mb-1">
          <v-row>
            <v-col cols="3" sm="2" md="1" class="pt-5 pr-0 pb-0">
              <span class="title pl-5">{{ $t('questions.answers.a') }}:</span>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.a.text.en"
                :rules="[rules.required]"
                :label="$t('languages.en')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.a.text.bg"
                :rules="[rules.required]"
                :label="$t('languages.bg')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pb-0">
              <v-checkbox
                class="mt-2 hidden-sm-and-down"
                v-model="question.answers.a.correct"
                color="primary"
                @change="selectCorrectAnswer('a')"
                :disabled="question.answers.a.correct"
              ></v-checkbox>
              <v-checkbox
                class="mt-2 hidden-md-and-up"
                v-model="question.answers.a.correct"
                color="primary"
                label="Correct answer:"
                @change="selectCorrectAnswer('a')"
                :disabled="question.answers.a.correct"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mb-1">
          <v-row>
            <v-col cols="3" sm="2" md="1" class="pt-5 pr-0 pb-0">
              <span class="title pl-5">{{ $t('questions.answers.b') }}:</span>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.b.text.en"
                :rules="[rules.required]"
                :label="$t('languages.en')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.b.text.bg"
                :rules="[rules.required]"
                :label="$t('languages.bg')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pb-0">
              <v-checkbox
                class="mt-2 hidden-sm-and-down"
                v-model="question.answers.b.correct"
                color="primary"
                @change="selectCorrectAnswer('b')"
                :disabled="question.answers.b.correct"
              ></v-checkbox>
              <v-checkbox
                class="mt-2 hidden-md-and-up"
                v-model="question.answers.b.correct"
                color="primary"
                label="Correct answer:"
                @change="selectCorrectAnswer('b')"
                :disabled="question.answers.b.correct"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mb-5">
          <v-row>
            <v-col cols="3" sm="2" md="1" class="pt-5 pr-0 pb-0">
              <span class="title pl-5">{{ $t('questions.answers.c') }}:</span>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.c.text.en"
                :rules="[rules.required]"
                :label="$t('languages.en')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="pb-0">
              <v-text-field
                solo
                v-model="question.answers.c.text.bg"
                :rules="[rules.required]"
                :label="$t('languages.bg')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pb-0">
              <v-checkbox
                class="mt-2 hidden-sm-and-down"
                v-model="question.answers.c.correct"
                color="primary"
                :disabled="question.answers.c.correct"
                @change="selectCorrectAnswer('c')"
              ></v-checkbox>
              <v-checkbox
                class="mt-2 hidden-md-and-up"
                v-model="question.answers.c.correct"
                color="primary"
                label="Correct answer:"
                :disabled="question.answers.c.correct"
                @change="selectCorrectAnswer('c')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-4" @click="saveQuestion()">
          {{ $t('common.save') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import validators from '../../../core/validators';
import questionTypes from '../../../enums/question.types';

export default {
  name: 'manage-question-form',
  props: {
    edittedQuestion: {
      type: Object
    },
    save: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      question: {
        text: {
          en: '',
          bg: ''
        },
        type: questionTypes.trueOrFalse,
        boolAnswer: true,
        answers: {
          a: {
            text: {
              en: '',
              bg: ''
            },
            correct: true
          },
          b: {
            text: {
              en: '',
              bg: ''
            },
            correct: false
          },
          c: {
            text: {
              en: '',
              bg: ''
            },
            correct: false
          }
        }
      },
      lastCorrectAnswer: 'a',
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      },
      questionTypes,
      questionTypesList: Object.values(questionTypes)
    };
  },
  created() {
    if (this.edittedQuestion.id) {
      this.question = {
        ...this.question,
        ...this.edittedQuestion
      };

      this.populateLastSelectedAnswer();
    }
  },
  methods: {
    populateLastSelectedAnswer() {
      for (const [key, value] of Object.entries(this.question.answers)) {
        if (value.correct) {
          this.lastCorrectAnswer = key;
          break;
        }
      }
    },
    selectCorrectAnswer(answer) {
      this.question.answers[this.lastCorrectAnswer].correct = false;
      this.lastCorrectAnswer = answer;
    },
    saveQuestion() {
      if (!this.$refs.manageQuestionForm.validate()) {
        return;
      }

      this.save(this.question);
    }
  }
};
</script>
