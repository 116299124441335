<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-title>
        <span v-if="this.question.id">
          {{ $t('questions.editQuestion') }}
        </span>
        <span v-else>{{ $t('questions.createQuestion') }}</span>
        <div class="flex-grow-1"></div>
        <v-btn depressed color="primary" to="/questions">
          <v-icon>mdi-chevron-left</v-icon>
          <span>{{ $t('questions.backToQuestions') }}</span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <manage-question-form
          v-if="!loading"
          :editted-question="question"
          :save="save"
        ></manage-question-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ManageQuestionForm from '../components/ManageQuestionForm';
import questionsHttpService from '../services/questions.http.service';
import questionsMapper from '../mappers/questions.mapper';
import questionTypes from '../../../enums/question.types';

export default {
  name: 'manage-question-page',
  components: {
    ManageQuestionForm
  },
  data() {
    return {
      question: {},
      loading: false
    };
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      this.loading = true;
      const { result } = await questionsHttpService.get(id);
      this.question = result;
      this.loading = false;
    }
  },
  methods: {
    async save(payload) {
      const body =
        payload.type === questionTypes.trueOrFalse
          ? questionsMapper.transformToQuestionTrueFalseModel(payload)
          : questionsMapper.transformToQuestionAnswersModel(payload);

      if (payload.id) {
        await questionsHttpService.update(payload.id, body);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('questions.updateSuccess')
        });
      } else {
        await questionsHttpService.create(body);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('questions.createSuccess')
        });
      }

      this.$router.push({ name: 'questions' });
    }
  }
};
</script>
